import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';

import { ReactComponent as CameraCapture } from '../icons/CameraCapture.svg';

const Camera = () => {
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const [isCapturing, setIsCapturing] = useState(false);
  const [countdown, setCountdown] = useState(5);

  const videoConstraints= {
    width: 1920,
    height: 1080,
    aspectRatio: 1.777777778,
  };

  const capture = () => {
    setIsCapturing(true);
    const countdownInterval = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(countdownInterval);
      const imageSrc = webcamRef.current.getScreenshot({width: 1920, height: 1080});
      localStorage.setItem('capturedImage', imageSrc);
      setIsCapturing(false);
      navigate('/preview');
    }, 5000);
  };

  return (
    <div className='flex size-full'>
      <Webcam
        ref={webcamRef}
        videoConstraints={videoConstraints}
        audio={false}
        className='size-full relative'
      />
      <button id="capture-button" onClick={capture} disabled={isCapturing} className="bg-white rounded-3xl w-20 h-20 flex items-center justify-center translate-x-[-50%] left-[50%] bottom-16 absolute">
        {isCapturing ? <span className='text-5xl text-red-500 font-semibold'>{countdown}</span> : <CameraCapture className='h-12 w-12'/>}
      </button>
    </div>
  );
};

export default Camera;
