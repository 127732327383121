import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Camera from "./components/Camera";
import PicturePreview from "./components/PicturePreview";
import PasswordProtect from "./components/PasswordProtect";

function App() {
  return (
    <Router>
      <div className="overflow-hidden h-screen w-full bg-gradient-to-br from-yellow-400 via-yellow-600 to-pink-600 bg-cover bg-center">
        <PasswordProtect>
          <Routes>
            <Route path="/" element={<Camera />} />
            <Route path="/preview" element={<PicturePreview />} />
          </Routes>
        </PasswordProtect>
      </div>
    </Router>
  );
}

export default App;
